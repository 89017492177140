import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

const withdrawalsClasses = {
    "2": "withdrawal-past-due",
    "1": "withdrawal-due-today",
    "0": "withdrawal-due-later"
  }

const DataTable = (props) => {

    const { 
        tableData, 
        columns, 
        filter, 
        onFilterChange,
        loading,
        total,
        checkboxSelection,
        multipleCheckboxSelection,
        onRowSelectChange,
        forceClearSelection,
        fullHeight,
        height,
        maxHeight,
        columnWidth,
        size        
    } = props;

    const [order, setOrder] = useState(filter?.order ?? "desc");
    const [orderBy, setOrderBy] = useState(filter?.orderBy);
    const [selectedRows, setSelectedRows] = useState([]);

    const pageSize = filter?.pageSize ?? 25


    useEffect(()=>{
        if(onRowSelectChange){
            onRowSelectChange(selectedRows)
        }
    },[selectedRows])

    useEffect(()=>{
        if(forceClearSelection){
            setSelectedRows([]);
        }
    },[forceClearSelection])



    const handleRequestSort = (event, property) => {

        const orderDir = orderBy === property && order === 'asc' ? 'desc' : 'asc';
        setOrder(() => orderDir);
        setOrderBy(() => property);

        onFilterChange({
            order,
            orderBy: property
        })
    };

    const onRowSelect = (row) => {

        const index = selectedRows.indexOf(row);

        let newSelected;
        if(multipleCheckboxSelection){
            newSelected = index !== -1 ? selectedRows.filter((v,i)=> i !== index) : [...selectedRows, row]
        }else{
            newSelected = index !== -1 ? [] : [row]
        }
     
        setSelectedRows(newSelected)
        
    }

    

    const onRowSelectAll = (event) => {
        if (event.target.checked) {
            const newSelected = tableData?.map((n, idx) => idx);
            setSelectedRows(newSelected);
            return;
        }
        setSelectedRows([]);
    };



    const TableHeader = (props) => {
        const { columns, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            if(property.sortable === false){
                return;
            }
            onRequestSort(event, property.id);
        };

        const rowCount = tableData?.length;
        const numSelected  = selectedRows.length

        return (
            <TableHead>
                <TableRow>
                    {(multipleCheckboxSelection || checkboxSelection) && (
                        <TableCell key={-1}>
                            <Box>
                                {multipleCheckboxSelection && (
                                    <Checkbox
                                        color="primary"
                                        indeterminate={numSelected > 0 && numSelected < rowCount}
                                        checked={rowCount > 0 && numSelected === rowCount}
                                        onChange={(e)=>onRowSelectAll(e)}
                                    />
                                )}
                            </Box>
                        </TableCell>
                    )}
                    {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            sortDirection={orderBy === column.id ? order : false}
                            width={column?.width}
                        >
                            <TableSortLabel
                                active={orderBy === column.id && column.sortable !== false}
                                direction={orderBy === column.id ? order : 'asc'}
                                onClick={createSortHandler(column)}
                                sx={{fontWeight: 700}}
                                hideSortIcon={column.sortable === false}
                            >
                                {column.label}
                                {orderBy === column.id && column.sortable !== false ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const PageTotals = (props) => {
        return (
            <div style={{flex: "1 1 0%", textAlign: "left"}}>
                 
                {props.page && props.pageSize && (
                    `Showing ${Math.min(props.total, props.page * props.pageSize - props.pageSize + 1)}-${Math.min(props.total, props.page * props.pageSize)} `
                )}
                {Number(props.total) > -1 && (
                    <span>from <b>{props.total}</b> total</span>
                )}
            </div>
        )
    }

    const pageChange = (e, value) => {
        setSelectedRows([]);
        onFilterChange({page: value})
    }

    return (
        <Box className='relative'>
            {loading && (
                <Box className="loader-mask">
                    <CircularProgress className="loader" />
                </Box>
            )}
            <TableContainer sx={{ height, maxHeight }} className={`table-container relative ${fullHeight ? 'full-height' : 'normal'}`}>
                <Table
                    sx={{ minWidth: 500, maxWidth: '90vw', height, maxHeight}}
                    aria-labelledby="sticky table"
                    size={size ?? "medium"}
                    stickyHeader
                >
                    <TableHeader
                        columns={columns}
                        order={filter?.order}
                        orderBy={filter?.orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {tableData?.map((row, index) => {

                            const isItemSelected = selectedRows.indexOf(index) !== -1;

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={index}
                                    sx={{ cursor: 'pointer' }}
                                    aria-checked={isItemSelected}
                                    selected={isItemSelected}
                                    class={row.dueDateColor ? withdrawalsClasses[row.dueDateColor.toString()]: null}
                                >
                                    {(multipleCheckboxSelection || checkboxSelection) && (
                                        <TableCell>
                                            <Checkbox
                                                color="primary"
                                                onChange={()=>onRowSelect(index)}
                                                checked={isItemSelected}
                                                sx={{p:0}}
                                            />
                                        </TableCell>
                                    )}
                                    {columns.map((col, idx) => {
                                        let colVal = row[col.id]
                                        if (col.parse) {
                                            colVal = col.parse(row[col.id], row)
                                        }
                                        return <TableCell sx={columnWidth ? {width: `${columnWidth}px !important`}: null} key={idx}>{colVal}</TableCell>
                                    })}
                                </TableRow>
                            );
                        })}

                        {tableData?.length === 0 && !loading && (
                            <TableRow><TableCell colSpan={(checkboxSelection || multipleCheckboxSelection ? columns.length + 1 : columns.length)} align='center'>No Data</TableCell></TableRow>
                        )}
                        
                    </TableBody>
                </Table>
            </TableContainer>
            { Number(total) > -1 && pageSize && (
                <Box className="table-footer">
                    <Stack spacing={3} alignItems="center" direction="row">
                        <PageTotals 
                            total={total}
                            page={filter?.page}
                            pageSize={pageSize}
                        />
                        <Pagination
                            count={Math.ceil(total / pageSize)}
                            page={filter?.page}
                            showFirstButton
                            showLastButton
                            onChange={pageChange}
                            color="secondary"
                            style={{flex: "1 1 auto"}}
                        />
                    </Stack>
                </Box>
            )}
        </Box>
    );

}


export default DataTable;