import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import ActionButton from 'components/buttons/Action_Button.js';
import Backend from 'Backend.js';

const backendURLs = Backend.backendURLs;
const approveWithdrawURL = backendURLs.approveWithdrawUrl;
const rejectWithdrawURL = backendURLs.rejectWithdrawUrl;

const content = require('content.json');
const withdrawalsActions = content.withdrawals_actions;
const configuration = require('configuration.json');
const withdrawalActionTypes = configuration.withdrawals_action_types;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    height: '95%',
    bgcolor: '#ffffff',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    fontFamily: 'Poppins',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
};

const ButtonWithdrawal = ({
    variant,
    Component,
    username,
    id,
    userID,
    isOffer,
    email,
    platform,
    treat_coins,
    withdrawal_amount,
    ID,
    setItems,
}) => {
    const [openModal, setOpenModal] = useState(false);

    const handleWithdrawals = (variant) => {
        switch (variant) {
            case 'approve':
                fetch(approveWithdrawURL, {
                    method: Backend.backendMethods.patch,
                    headers: Backend.generateHeader(),
                    body: JSON.stringify({ withdrawalID: ID, action: 3 })
                }).then(async (res) => {
                    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {

                        Backend.logOut();
                    }
                    setItems('approved', ID)
                });
                break;
            case 'reject':
                fetch(rejectWithdrawURL, {
                    method: Backend.backendMethods.patch,
                    headers: Backend.generateHeader(),
                    body: JSON.stringify({ withdrawalID: ID, action: 2 })
                }).then(async res => {
                    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {

                        Backend.logOut();
                    }
                    
                    setItems('rejected', ID)
                });
                break;

            default:
                break;
        }
    };

    return (
        <div>
            <button onClick={() => { setOpenModal(true); }} className={`btn-withdrawal ${variant}`}>
                {
                    Component && <Component />
                }
            </button>
            {openModal && (
                <Modal
                    open={openModal}
                    onClose={() => {
                        setOpenModal(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div>
                            <h4 className={`modal-heading modal-heading-${variant}`}>
                                {withdrawalsActions[variant]}
                            </h4>
                            <div className="withdrawals-modal-line">
                                <p>
                                    User ID: <span>#{userID}</span>
                                </p>
                                <p>
                                    Username: <span>{username}</span>
                                </p>
                            </div>
                            <div className="withdrawals-modal-line">
                                <p>
                                    Email Address: <span>{email}</span>
                                </p>
                                <p>
                                    Platform: <span>{platform}</span>
                                </p>
                            </div>
                            <div className="withdrawals-modal-line">
                                <p>
                                    Treat Coins Amout to Withdraw: <span>{withdrawal_amount}</span>
                                </p>
                            </div>
                        </div>

                        <div className="withdrawals-modal-buttons">
                            <ActionButton
                                onClick={() => {
                                    setOpenModal(false);
                                }}
                                type="cancel"
                                text="Cancel"
                            />
                            <ActionButton
                                onClick={() => {
                                    setOpenModal(false);
                                    handleWithdrawals(variant);
                                }}
                                type={variant}
                                text={variant === withdrawalActionTypes.reject ? 'Reject' : 'Approve'}
                            />
                        </div>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default ButtonWithdrawal;