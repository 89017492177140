import Backend from 'Backend';

export const apiGetBannedPostbacks = async (filter) => {
    const queryString = new URLSearchParams(filter).toString();
    const res = await fetch(`${Backend.backendURLs.getBannedPostbacksURL}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

};