import Backend from 'Backend';
import Alerts from 'utils/Alerts.js';

const configuration = require('configuration.json');

export const apiGetUserOfferCompleteHistory = async (userId) => {

    const res = await fetch(`${Backend.backendURLs.offerCompleteHistoryUrl}?userId=${userId}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

};


export const apiGetUsers = async (filter) => {

    const queryString = new URLSearchParams(filter).toString();

    const res = await fetch(`${Backend.backendURLs.listUsersUrl}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    } else if (res.status === Backend.backendResponseCodes.OKCode) {
        return await res.json();
    } else {
        Alerts.showErrorAlert('Something went wrong');
    }
};


export const apiDeleteUser = async (userId, adminComments) => {

    const res = await fetch(Backend.backendURLs.usersActionUrl, {
        method: Backend.backendMethods.delete,
        headers: Backend.generateHeader(),
        body: JSON.stringify({ id: userId, action: configuration.user_status_change_actions.deleted, adminComments })
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    } else if (res.status === Backend.backendResponseCodes.OKCode) {
        Alerts.showSuccessAlert(`User with uniqueID${userId} deleted`);
        return await res.json();
    } else {
        let response = await res.json();
        Alerts.showErrorAlert(response.message || 'Something went wrong');
    }
};


export const apiChangeUserStatus = async (userId, status, adminComments) => {

    const res = await fetch(Backend.backendURLs.usersActionUrl, {
        method: Backend.backendMethods.patch,
        headers: Backend.generateHeader(),
        body: JSON.stringify({ id: userId, action: status, adminComments })
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    } else if (res.status === Backend.backendResponseCodes.OKCode) {
        setTimeout(() => {
            Alerts.showSuccessAlert(`User with uniqueID${userId} ${status === 2 ? 'Blocked' : 'Unblocked'}`);
        }, 10);
        return await res.json();
    } else {
        let response = await res.json();
        setTimeout(() => {
            Alerts.showErrorAlert(response.message || 'Something went wrong');
            
        }, 10);
    }
};


export const apiClearUser = async (userId) => {

    const res = await fetch(Backend.backendURLs.usersActionUrl, {
        method: Backend.backendMethods.patch,
        headers: Backend.generateHeader(),
        body: JSON.stringify({ id: userId, action: configuration.user_status_change_actions.cleared })
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }
    else if (res.status === Backend.backendResponseCodes.OKCode) {
        return await res.json();
    } else {
        let response = await res.json();
        Alerts.showErrorAlert(response.message || 'Something went wrong');
    }
}


export const apiCompleteUserOffer = async (userId, offerUniqueId, sessionId) => {

    const res = await fetch(Backend.backendURLs.offerCompleteAddUrl, {
        method: Backend.backendMethods.post,
        headers: Backend.generateHeader(),
        body: JSON.stringify({ userId, offerUniqueId, sessionId }),
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    } else if (res.status === Backend.backendResponseCodes.OKCode) {
        return await res.json();
    } else {
        Alerts.showErrorAlert('Something went wrong');
    }
};
