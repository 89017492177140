import { TextField } from '@mui/material';
import { useState } from 'react';
import ActionButton from 'components/buttons/Action_Button.js';

function AddNoteModalForm({ handleCancel, handleConfirm, actionData }) {
    const [data, setData] = useState({ ...actionData });

    return (
        <>
            <div className='action-popup-container'>
                <TextField
                    onChange={(e) => {
                        setData(prev => ({ ...prev, header: e.target.value }));
                    }}
                    defaultValue={actionData?.header}
                    inputProps={{ maxLength: 50 }}
                    id="outlined-basic" variant="outlined" />
                <TextField
                    onChange={(e) => {
                        setData(prev => ({ ...prev, note: e.target.value }));
                    }}
                    defaultValue={actionData?.note}
                    style={{ marginBottom: 23 }}
                    id="outlined-multiline-flexible"
                    multiline
                    inputProps={{ maxLength: 1000 }}
                    minRows={4}
                />
            </div>
            <div className="confirm-action-modal-buttons">
                <ActionButton
                    onClick={handleCancel}
                    type="cancel"
                    text="Cancel"
                    className={'width-185'}
                />
                <ActionButton
                    disabled={!data.header || !data.note}
                    onClick={() => {
                        const sendData = {
                            header: data.header, note: data.note,
                        };
                        if (data.noteID) {
                            sendData['noteID'] = data?.noteID;
                        }
                        actionData.handleConfirm(sendData);
                        handleConfirm();
                    }}
                    className={'width-185'}
                    type="edit"
                    text={actionData?.confirmBtnText || 'OK'}
                />
            </div>
        </>
    );
}

export default AddNoteModalForm;