import React, { useState } from 'react';
import styled from '@emotion/styled';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import Header from 'components/text/Header.js';

import DistributionsTab from 'components/competitions/Distributions_Tab.js';
import WinnersTab from 'components/competitions/Winners_Tab.js';

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: 0
}));

const Competitions = () => {
    const [currentTab, setCurrentTab] = useState('1');

    const onTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <div className='competitions-content'>
            <Header />
            <TabContext value={currentTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={onTabChange}
                        variant="scrollable"
                        scrollButtons={true}
                    >
                        <Tab label="Distributions" value="1" />
                        <Tab label="Winners" value="2" />
                    </TabList>
                </Box>
                <StyledTabPanel value="1">
                    <DistributionsTab />
                </StyledTabPanel>
                <StyledTabPanel value="2">
                    <WinnersTab />
                </StyledTabPanel>
            </TabContext>
        </div>
    );
};

export default Competitions;
