import { Box, Paper, Toolbar } from '@mui/material';
import { useState, useEffect } from 'react';
import DataTable from 'components/tables/DataTable.js';
import DataFilter from 'components/filters/DataFilter.js';
import SearchBySelect from 'components/inputs/SearchBySelect.js';
import SearchField from 'components/inputs/SearchField.js';
import { apiGetBannedPostbacks } from 'services/PostbacksService.js';

const initialFilter = JSON.parse(sessionStorage.getItem("bannedPostbacksFilter")) || {
    order: "desc",
    orderBy: "dateCreated",
    page: 1,
    pageSize: 10,
    search: "",
    searchBy: ""
};

const BannedPostbacksTable = () => {
    const [postbacks, setPostbacks] = useState([]);
    const [filter, setFilter] = useState(initialFilter);
    const [loader, setLoader] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [pageSizeInput, setPageSizeInput] = useState(filter.pageSize);

    const columns = [
        { label: 'Date Created', id: 'dateCreated', filterable: true },
        { label: 'Provider Name', id: 'providerName', filterable: true },
        { label: 'Unique User ID', id: 'uniqueUserID', filterable: true },
        { label: 'Username', id: 'username', filterable: true },
        { label: 'Request ID', id: 'requestID', filterable: true }
    ];

    const handlePageSizeChange = (e) => {
        const value = e.target.value;
        if (value === "" || (Number(value) > 0 && Number.isInteger(Number(value)))) {
            setPageSizeInput(value);
        }
    };

    const applyPageSize = () => {
        const newFilter = { ...filter, pageSize: Number(pageSizeInput) || 6, page: 1 };
        setFilter(newFilter);
        sessionStorage.setItem("bannedPostbacksFilter", JSON.stringify(newFilter));
    };

    const getPostbacks = async () => {
        setLoader(true);
        const result = await apiGetBannedPostbacks(filter);
        setPostbacks(result?.bannedPostbacks);
        setTotalRows(result?.total);
        setLoader(false);
    };

    useEffect(() => {
        getPostbacks();
        sessionStorage.setItem("bannedPostbacksFilter", JSON.stringify(filter));
    }, [filter]);

    const onFilterChange = newFilter => {
        setFilter(prev => ({ ...prev, ...newFilter }));
    };

    return (
        <div className="content-container">
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            justifyContent: "start",
                            columnGap: 1
                        }}
                    >
                        <Box className='flex gap-10'>
                            <h3>Banned Postbacks</h3>
                        </Box>
                        <DataFilter onChange={onFilterChange} submitLabel="Submit">
                            <SearchBySelect data={columns} />
                            <SearchField />
                        </DataFilter>
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '10px 20px' }}>
                            <label htmlFor="page-size-text">Custom Page Size:</label>
                            <input
                                type="text"
                                id="page-size-text"
                                value={pageSizeInput}
                                onChange={handlePageSizeChange}
                                style={{ padding: '5px', width: '80px', borderRadius: '5px', border: '1px solid #ccc' }}
                            />
                            <button
                                onClick={applyPageSize}
                                style={{
                                    padding: '5px 10px',
                                    backgroundColor: '#007BFF',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                }}
                            >
                                Apply
                            </button>
                        </Box>
                    </Toolbar>

                    <DataTable
                        columns={columns}
                        tableData={postbacks}
                        onFilterChange={onFilterChange}
                        filter={filter}
                        loading={loader}
                        total={totalRows}
                        fullHeight
                    />
                </Paper>
            </Box>
        </div>
    );
};

export default BannedPostbacksTable;