import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, MenuItem, TextField, Typography, CircularProgress } from '@mui/material';
import Alerts from 'utils/Alerts.js';
import { apiAddCompetitionDistribution, apiCompetitionsGetDistribution } from 'services/CompetitionsService.js';
import CompetitionDetails from './Competition_Details.js';

const DistributionsTab = () => {
    const [loading, setLoading] = useState(false);
    const [competitionDetails, setCompetitionDetails] = useState(null);
    const [formData, setFormData] = useState({
        rewardMonth: '',
        rewardYear: ''
    });

    const months = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' },
    ];

    const years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - i).map(year => ({
        value: year,
        label: year.toString()
    }));

    const handleChange = (field) => (event) => {
        setFormData((prev) => ({ ...prev, [field]: event.target.value }));
    };

    const fetchCompetitionDetails = async () => {
        setLoading(true);
        try {
            const details = await apiCompetitionsGetDistribution();
            setCompetitionDetails(details);
        } catch (err) {
            Alerts.showErrorAlert('Failed to fetch competition details');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const res = await apiAddCompetitionDistribution(formData);
            if (res.code===1) {
                Alerts.showSuccessAlert(res.message);
            } else {
                Alerts.showErrorAlert(res.message || 'Failed to create distribution'); 
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCompetitionDetails();
    }, []);

    return (
        <Box sx={{ padding: 4 }}>
            {loading && (
                <Box className="loader">
                    <CircularProgress />
                </Box>
            )}
            <Grid container spacing={3} justifyContent="center" alignItems="center">
                {/* Starting Month */}
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        select
                        label="Starting Month"
                        value={formData.month}
                        onChange={handleChange('rewardMonth')}
                        variant="outlined"
                    >
                        {months.map((month) => (
                            <MenuItem key={month.value} value={month.value}>
                                {month.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                {/* Starting Year */}
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        select
                        label="Starting Year"
                        value={formData.year}
                        onChange={handleChange('rewardYear')}
                        variant="outlined"
                    >
                        {years.map((year) => (
                            <MenuItem key={year.value} value={year.value}>
                                {year.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                {/* Create Distribution Button */}
                <Grid item xs={12} sm={4} textAlign="center">
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleSubmit}
                        disabled={!formData.rewardMonth || !formData.rewardYear}
                    >
                        Create Distribution
                    </Button>
                </Grid>
            </Grid>

            <Box sx={{ marginTop: 4, marginBottom:4, textAlign: 'center' }}>
                <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                    ** The distributions need to be added before the end of the competition
                </Typography>
            </Box>
            <CompetitionDetails data={competitionDetails} />
        </Box>
    );
};

export default DistributionsTab;
