import  { useState } from 'react';
import ActionButton from 'components/buttons/Action_Button.js';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';

function AddBonusFineModalForm({ actionData, handleCancel, handleConfirm }) {
    const [data, setData] = useState({ ...actionData });
    const [error, setError] = useState(false);

    const handleNumbersChange = (e) => {
        const value = e.target.value;
        const regex = /^\d+$/;

        if (regex.test(value)) {
            setError(false);
            setData(prev => ({ ...prev, amount: value }));
        } else {
            setError(true);
            e.target.value = '';
        }
    };
    return (
        <>
            <div className='action-popup-container'>
                <InputLabel htmlFor="outlined-basic">Amount</InputLabel>
                <TextField
                    error={error}
                    onChange={handleNumbersChange}
                    defaultValue={actionData?.amount}
                    id="outlined-basic"
                    variant="outlined"
                    type='number'
                />
                <InputLabel htmlFor="reason-field">Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="coins-type"
                    value={actionData.amountType}
                    onChange={(e) => {
                        setData(prev => ({ ...prev, amountType: e.target.value }));
                    }}
                >
                    {actionData.dropdown.map(i => <MenuItem value={i.value}>{i.label}</MenuItem>)}
                </Select>
                <InputLabel htmlFor="reason-field">Reason</InputLabel>
                <TextField
                    onChange={(e) => {
                        setData(prev => ({ ...prev, reason: e.target.value }));
                    }}
                    multiline
                    id="reason-field" variant="outlined" />
            </div>
            <div className="confirm-action-modal-buttons">
                <ActionButton
                    onClick={handleCancel}
                    type="cancel"
                    text="Cancel"
                    className={'width-185'}
                    />
                <ActionButton
                    onClick={() => {
                        actionData.handleConfirm({
                            ...data
                        });
                        handleConfirm();
                    }}
                    className={'width-185'}
                    type="edit"
                    text={actionData?.confirmBtnText || 'OK'}
                />
            </div>
        </>
    );
}

export default AddBonusFineModalForm;