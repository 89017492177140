import { createContext, useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Backend from 'Backend.js';
import UserData from 'User_Data.js';
import LoginButton from 'components/buttons/Login_Button.js';
import Main from 'Main.js';
import { loginRequest } from 'authConfig.js';
import jwt from 'jwt-decode';
import { UserContextProvider } from './contexts/UserContext.js';
import { Modal } from '@mui/material'
import { Box } from '@mui/system';

const withdrawalAlertStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    height: '500px',
    bgcolor: '#ffffff',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    fontFamily: 'Poppins',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
};

const AuthContext = createContext(null);
const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const backendURLs = Backend.backendURLs;
const loginURL = backendURLs.loginUrl;
let jwtToken = UserData.getItem(userDataKeys.jwt_token);
const globalConf = require('global_conf.json');
const azureAuth = globalConf.azure_auth;
const content = require('content.json');

function App() {
    const [authorized, setAuthorized] = useState(Boolean(jwtToken))
    const [openDueWithdrawalsAlert, setOpenDueWithdrawalsAlert] = useState(false);
    const { instance, accounts, inProgress } = useMsal()
    const [accessToken, setAccessToken] = useState(null)

    const getJwtTokenFromServer = (azureToken) => {
        fetch(loginURL, {
            method: Backend.backendMethods.post,
            headers: Backend.generateAzureHeader(azureToken)
        })
        .then(async (res) => {
            if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                Backend.logOut()
            } 
            const result = await res.json()
            const decoded = jwt(result.token);
            const adminName = decoded.adminName;

            dispatchEvent(new CustomEvent('adminNameChange', { detail: adminName }));

            UserData.setItem(
                userDataKeys.jwt_token,
                result.token
            )
            UserData.setItem(
                userDataKeys.admin_name,
                adminName
            )
            setAuthorized(UserData.getItem(userDataKeys.jwt_token));

        if (!UserData.getItem(userDataKeys.due_withdrawals_checked)) {
            checkDueWithdrawals();
        }
        })
        .catch(e => { console.log(e); })
    }

    function getAzureTokenFromSessionStorage() {
        let azureToken = null;
        const sessionData = Object.entries(sessionStorage);
        sessionData.forEach(([key, value]) => {
            if (key.includes('login.windows.net-idtoken'))
                azureToken = JSON.parse(value)['secret']
        });
        return azureToken;
    }

    function checkDueWithdrawals() {
        fetch(`${Backend.backendURLs.listWithdrawalsUrl}?checkDueWithdrawals=1`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader()
        })
        .then(async (res) => {
            if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                Backend.logOut()
            } 
            const result = await res.json()

            UserData.setItem(userDataKeys.due_withdrawals_checked, true);
            setOpenDueWithdrawalsAlert(Boolean(result.dueWithdrawalsExist));
        })
        .catch(e => { console.log(e); })
    }

    useEffect(() => {
            let azureToken = getAzureTokenFromSessionStorage();
            if (azureToken) {
                getJwtTokenFromServer(azureToken);
            }
    }, [inProgress])
        
    function RequestAzureAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(request)
            .then(response => {
                setAccessToken(response.accessToken)
            })
            .catch(e => {
                instance.acquireTokenPopup(request).then(response => {
                    setAccessToken(response.accessToken)
                })
            })
    }

    function logOut() {
        UserData.clear();
        const logoutRequest = {
            account: accounts[0],
            postLogoutRedirectUri: azureAuth.redirectUri,
        };
        instance.logoutRedirect(logoutRequest);
    }

    if (UserData.getItem(userDataKeys.logout_action)) {
        UserData.removeItem(userDataKeys.logout_action);
        logOut();
    }

    return (
        <UserContextProvider>
        <AuthContext.Provider
            value={{
                authorized,
                setAuthHandler: () => setAuthorized(true),
                logOutHandler: () => logOut(),
            }}
        >
            {authorized ?
                <AuthenticatedTemplate>
                    <Main />
                    {openDueWithdrawalsAlert && (
                <Modal
                    open={setOpenDueWithdrawalsAlert}
                    onClose={() => {
                        setOpenDueWithdrawalsAlert(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={withdrawalAlertStyle}>
                        <div>
                            <h4 className="modal-heading modal-heading-withdrawals-due">
                               {content.withdrawals_due_title}
                            </h4>
                            <div className="withdrawals-modal-line font bold font-18">
                                <p>
                                    {content.withdrawals_due_message}
                                </p>
                            </div>
                        </div>

                        <div className="due-withdrawals-button-container">
                            <button
                            onClick={() => { setOpenDueWithdrawalsAlert(false); }}
                                className="swal2-cancel swal2-styled font-18"
                            >
                            {content.got_it}
                            </button>
                        </div>
                    </Box>
                </Modal>
            )}
                </AuthenticatedTemplate>
                : <></>}
            <UnauthenticatedTemplate>
                <div className="login-layout_azure">
                    <LoginButton text="Login" onClick={RequestAzureAccessToken} />
                </div>
                    </UnauthenticatedTemplate>
        </AuthContext.Provider>
        </UserContextProvider>
    )
}

export { AuthContext }
export default App
