import ActionButton from 'components/buttons/Action_Button.js';

function SimulateChargebackModalForm({ actionData, handleCancel, handleConfirm }) {
  
    return (
        <>
            <p className='confirm-action-modal-comments'>{actionData?.text}</p>
            <div className="confirm-action-modal-buttons">
                <ActionButton
                    onClick={handleCancel}
                    type="cancel"
                    text="Cancel"
                    className={'width-185'}
                />
                <ActionButton
                    onClick={() => {
                        actionData.handleConfirm(actionData);
                        handleConfirm();
                    }}
                    className={'width-185'}
                    type="edit"
                    text={actionData?.confirmBtnText || 'OK'}
                />
            </div>
        </>
    );
}

export default SimulateChargebackModalForm;