import Backend from 'Backend';

export const apiAddCompetitionDistribution = async ({ rewardMonth, rewardYear }) => {
    const res = await fetch(Backend.backendURLs.competitionsAddDistributionURL, {
        method: Backend.backendMethods.post,
        body: JSON.stringify({
            rewardMonth,
            rewardYear
        }),
        headers: Backend.generateHeader(),
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();
};

export const apiCompetitionsGetDistribution = async () => {
    const res = await fetch(Backend.backendURLs.competitionsGetDistributionURL, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader(),
    });
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }
    return await res.json();
};

export const apiArchiveCompetitionScores = async () => {
    const res = await fetch(Backend.backendURLs.competitionsArchiveScoresURL, {
        method: Backend.backendMethods.post,
        headers: Backend.generateHeader(),
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();
};

export const apiGetInitialCompetitionList = async () => {
    const res = await fetch(Backend.backendURLs.competitionsGetInitialListURL, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader(),
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();
};

export const apiGiveCompetitionCoins = async (debugMode = 0, insertCoins = 1) => {
    const res = await fetch(Backend.backendURLs.competitionsGiveCoinsURL, {
        method: Backend.backendMethods.post,
        body: JSON.stringify({
            debugMode,
            insertCoins
        }),
        headers: Backend.generateHeader(),
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();
};
