import { TextField } from '@mui/material';
import debounce from 'debounce';
import { useState } from 'react';
import { apiChangeUserStatus } from 'services/UsersService.js';
import ActionButton from 'components/buttons/Action_Button.js';

const configuration = require('configuration.json');
const userStatusChangeActions = configuration.user_status_change_actions;

function DeleteSuspendModalForm({ actionData, handleCancel, handleConfirm }) {
    const [data, setData] = useState(actionData || {});

    const clickConfirm = async () => {
        actionData.setLoader(true);
        await apiChangeUserStatus(actionData.userID, userStatusChangeActions[actionData.type], data.adminComments);
        actionData.callback();
        actionData.setLoader(false);
        handleConfirm();
    };

    return (
        <>
            {actionData?.currentComments ?
                <p className='confirm-action-modal-comments'>Previous comments: <span>{actionData?.currentComments}</span></p>
                : <></>
            }
            <TextField
                placeholder='Add some comment'
                onChange={debounce((e) => {
                    setData({ ...actionData, adminComments: e.target.value });
                }, 300)}
                style={{ marginBottom: 23, width: '100%' }}
                multiline
                inputProps={{ maxLength: 1000 }}
                minRows={4}
            />
            <p className='confirm-action-modal-comments'>{actionData?.text}</p>
            <div className="confirm-action-modal-buttons">
                <ActionButton
                    onClick={handleCancel}
                    type="cancel"
                    text="Cancel"
                    className={'width-185'}
                />
                <ActionButton
                    onClick={clickConfirm}
                    className={'width-185'}
                    type="edit"
                    text={actionData?.confirmBtnText || 'OK'}
                />
            </div>
        </>
    );
}

export default DeleteSuspendModalForm;