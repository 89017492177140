import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import GiveBonusModalForm from 'components/forms/Give_Bonus_Modal_Form.js';
import EditTicketModalForm from 'components/forms/Edit_Ticket_Modal_Form.js';
import AddTicketModalForm from 'components/forms/Add_Ticket_Modal_Form.js';
import CloseTicketModalForm from 'components/forms/Close_Ticket_Modal_Form.js';
import DeactivateOfferModalForm from 'components/forms/Deactivate_Offer_Modal_Form.js';
import CommentTicketModalForm from 'components/forms/Comment_Ticket_Modal_Form.js';
import FetchOffersModalForm from 'components/forms/Fetch_Offers_Modal_Form.js';
import ActivateOfferModalForm from 'components/forms/Activate_Offer_Modal_Form.js';
import ManageSubcriptionsModalForm from 'components/forms/Manage_Subcriptions_Modal_Form.js';
import withSubcriptionData from 'components/hocs/withSubcriptionData.js';
import SendMailTelegramModalForm from 'components/forms/Send_Mail_Telegram_Modal_Form.js';
import AddDescriptionModalForm from 'components/forms/Add_Description_Modal_Form.js';
import AddMainTitleModalForm from 'components/forms/Add_Main_Title_Modal_Form.js';
import OfferDifficultyLevelModalForm from 'components/forms/Offer_Difficulty_Level_Modal_Form.js';
import OfferThemesModalForm from 'components/forms/Offer_Themes_Modal_Form.js';
import OfferCashbackCategoryModalForm from 'components/forms/Offer_Cashback_Category_Modal_Form.js';
import OfferTypesModalForm from 'components/forms/Offer_Types_Modal_Form.js';
import OfferBannerModalForm from 'components/forms/Offer_Banner_Modal_Form.js';
import OfferIconModalForm from 'components/forms/Offer_Icon_Modal_Form.js';
import AddSystemPayoutModalForm from 'components/forms/Add_System_Payout_Modal_Form.js';
import AddCoinsModalForm from 'components/forms/Add_Coins_Modal_Form.js';
import AddCoinsReferralModalForm from 'components/forms/Add_CoinsReferral_Modal_Form.js';
import AddCoins2ndGenModalForm from 'components/forms/Add_Coins2ndGen_Modal_Form.js';
import AddCoins3rdGenModalForm from 'components/forms/Add_Coins3rdGen_Modal_Form.js';
import AddPercentageModalForm from 'components/forms/Add_Percentage_Modal_Form.js';
import AddPercentageReferralModalForm from 'components/forms/Add_PercentageReferral_Modal_Form.js';
import AddPercentage2ndGenModalForm from 'components/forms/Add_Percentage2ndGen_Modal_Form.js';
import AddPercentage3rdGenModalForm from 'components/forms/Add_Percentage3rdGen_Modal_Form.js';
import SetPlatformTypeModalForm from 'components/forms/Set_Platform_Type_Modal_Form.js';
import OfferCategoryModalForm from 'components/forms/Offer_Category_Modal_Form.js';
import AddAppnameModalForm from 'components/forms/Add_Appname_Modal_Form.js';
import ValidateModalForm from 'components/forms/Validate_Modal_Form.js';
import AddFreeOfferModalForm from 'components/forms/Add_Free_Offer_Modal_Form.js';
import AddGuidesModalForm from 'components/forms/Add_Guides_Modal_Form.js';
import EditOfferPositionModalForm from 'components/forms/Edit_Offer_Position_Modal_Form.js';
import AddNewOfferForm from 'components/forms/Add_New_Offer_Modal_Form.js';
import AddNewProviderModalForm from 'components/forms/Add_New_Provider_Modal_Form.js';
import OfferCompleteModalForm from 'components/forms/Offer_Complete_Modal_Form.js';
import VideoGuidelineUploaderForm from 'components/forms/VideoGuidelineUploaderForm.js';
import ConfirmForm from 'components/forms/ConfirmForm.js';
import EditClickthroughModalForm from 'components/forms/EditClickthroughModalForm.js';
import EditClickthroughCapModalForm from 'components/forms/EditClickthroughCapModalForm.js';
import StockOffersModalForm from 'components/forms/StockOffersModalForm.js';
import SecondConversionModalForm from 'components/forms/SecondConversionModalForm.js';
import OfferReviewTopicModalForm from 'components/forms/OfferReviewTopicModalForm.js';
import ValidateCoinsModalForm from 'components/forms/Validate_Coins_Modal_Form.js';
import EditAPIKeyModalForm from 'components/forms/Edit_API_Key_Modal_Form.js';
import EditCallbackSecretModalForm from 'components/forms/Edit_Callback_Secret_Modal_Form.js';
import ActivateOfferSuccess from 'components/forms/Activate_Offer_Success.js';
import DeleteSuspendModalForm from 'components/forms/Delete_Suspend_User_Form.js';
import AddNoteModalForm from 'components/forms/Add_Note_Modal_Form.js';
import AddBonusFineModalForm from 'components/forms/Add_Fine_Bonus_Modal_Form.js';
import SimulateChargebackModalForm from 'components/forms/Simulate_Chargeback_Modal_Form.js';
import WithdrawalModalForm from 'components/forms/Withdrawal_Modal_Form.js';


const content = require('content.json');
const configuration = require('configuration.json');
const withdrawalActionTypes = configuration.withdrawals_action_types;

const MySwal = withReactContent(Swal);

class Alerts {
    static async showSuccessAlert(message) {
        await MySwal.fire({
            html: '<p class="successful-alert">' + message + '</p>',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            timer: 5000,
            customClass: {
                container: 'margin-0 padding-0',
                popup: 'success-alert-popup padding-bottom-10',
                closeButton: 'successful-alert'
            }
        });
    }

    static showErrorAlert(message) {
        MySwal.fire({
            html: '<p class="successful-alert">' + message + '</p>',
            background: '#EC1717',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            timer: 5000,
            customClass: {
                container: 'margin-0 padding-bottom-10',
                popup: 'success-alert-popup padding-0',
                closeButton: 'successful-alert',
                htmlContainer: 'padding-bottom-10'
            }
        });
    }

    static showConfirmAlert(message, callback) {
        const popup = MySwal;
        const html = <ConfirmForm
            message={message}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={callback}
        />;

        MySwal.fire({
            html: message,
            title: 'Attention!',
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: true,
            showConfirmButton: true,
            allowOutsideClick: true,
            width: 500,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-30 overflow-visible'
            },
        }).then((result) => {
            if (result.isConfirmed) {
                callback(true)
            } else{
                callback(false)
            }
        })
    }

    static showGiveBonusAlert() {
        const popup = MySwal;
        const html = <GiveBonusModalForm handleCancel={popup.clickCancel} handleConfirm={popup.clickConfirm} />;

        popup.fire({
            html,
            title: content.give_bonus,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showSendMailAlert() {
        const popup = MySwal;
        const html = <SendMailTelegramModalForm handleCancel={popup.clickCancel} handleConfirm={popup.clickConfirm} />;

        popup.fire({
            html,
            title: content.send_email,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showSendTelegramAlert() {
        const popup = MySwal;
        const html = <SendMailTelegramModalForm handleCancel={popup.clickCancel} handleConfirm={popup.clickConfirm} />;

        popup.fire({
            html,
            title: content.send_telegram,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showEditTicketAlert() {
        const popup = MySwal;
        const html = <EditTicketModalForm handleCancel={popup.clickCancel} handleConfirm={popup.clickConfirm} />;

        popup.fire({
            html,
            title: content.edit_ticket,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showAddTicketAlert() {
        const popup = MySwal;
        const html = <AddTicketModalForm handleCancel={popup.clickCancel} handleConfirm={popup.clickConfirm} />;

        popup.fire({
            html,
            title: content.add_ticket,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showCommentTicketAlert() {
        const popup = MySwal;
        const html = <CommentTicketModalForm handleCancel={popup.clickCancel} handleConfirm={popup.clickConfirm} />;

        popup.fire({
            html,
            title: content.comment_ticket,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showCloseTicketAlert() {
        const popup = MySwal;
        const html = <CloseTicketModalForm handleCancel={popup.clickCancel} handleConfirm={popup.clickConfirm} />;

        popup.fire({
            html,
            title: content.close_ticket,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showDeactivateOfferAlert() {
        const popup = MySwal;
        const html = <DeactivateOfferModalForm handleCancel={popup.clickCancel} handleConfirm={popup.clickConfirm} />;

        popup.fire({
            html,
            title: content.deactivate_offer,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showFetchOffersAlert() {
        const popup = MySwal;
        const html = <FetchOffersModalForm handleCancel={popup.clickCancel} handleConfirm={popup.clickConfirm} />;

        popup.fire({
            html,
            title: content.fetch_offers,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showAddFreeOfferModal({
        ID,
        isPercentageOffer,
        MinBalanceToShow,
        MaxBalanceToShow,
        ClicksCap,
        AvailableForReferrals,
        DisplayToPaidUsersOnly,
        DisplayRecentActivityThreshold,
        DisplayCap,
        IsFree
    }) {
        const popup = MySwal;
        const html = <AddFreeOfferModalForm 
            handleConfirm={popup.clickConfirm} 
            offerID={ID}
            isPercentageOffer={isPercentageOffer}
            disabled={IsFree}
            infoInitial = {{
                MinBalanceToShow,
                MaxBalanceToShow,
                ClicksCap,
                AvailableForReferrals,
                DisplayToPaidUsersOnly,
                DisplayRecentActivityThreshold,
                DisplayCap
            }}
        />;

        popup.fire({
            html,
            title: content.free_offer,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left capitalize',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showDifficultyLevelSelectAlert(value, offerID, cb) {
        const popup = MySwal;
        const html = <OfferDifficultyLevelModalForm
            offerID={offerID}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: content.select_difficulty_level,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showOfferThemesSelectAlert(value, offerID, cb) {
        const popup = MySwal;
        const html = <OfferThemesModalForm
            offerID={offerID}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: content.select_offer_theme,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showOfferTypesSelectAlert(value, offerID, cb) {
        const popup = MySwal;
        const html = <OfferTypesModalForm
            offerID={offerID}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: content.select_offer_type,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showOfferBannerAlert({ isIcon, offerID, updateItems }) {
        const title = !isIcon ? content.add_banner : content.add_icon;
        const popup = MySwal;
        const html = <OfferBannerModalForm
            updateItems={updateItems}
            offerID={offerID}
            isIcon={isIcon}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
        />;

        popup.fire({
            html,
            title,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showOfferIconAlert({ isIcon, offerID, updateItems }) {
        const title = !isIcon ? content.add_banner : content.add_icon;
        const popup = MySwal;
        const html = <OfferIconModalForm
            updateItems={updateItems}
            offerID={offerID}
            isIcon={isIcon}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
        />;

        popup.fire({
            html,
            title,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showCashbackCategorySelectAlert(value, offerID, cb) {
        const popup = MySwal;
        const html = <OfferCashbackCategoryModalForm
            offerID={offerID}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: content.select_cashback_category,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showCategorySelectAlert(value, offerID, cb) {
        const popup = MySwal;
        const html = <OfferCategoryModalForm
            offerID={offerID}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: content.select_category,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showAddDescriptionAlert(id, uniqueOfferID, value, cb, header, field) {
        const popup = MySwal;
        const html = <AddDescriptionModalForm
            field={field}
            id={id}
            uniqueOfferID={uniqueOfferID}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: header || content.edit_description,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showMainTitleAlert(id, uniqueOfferID, value, cb) {
        const popup = MySwal;
        const html = <AddMainTitleModalForm
            id={id}
            value={value}
            uniqueOfferID={uniqueOfferID}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: content.edit_main_title,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showAddAppnameAlert(id, value, cb) {
        const popup = MySwal;
        const html = <AddAppnameModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: value ? content.edit_appname : content.add_appname,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showAddCoinsAlert(id, value, cb) {
        const popup = MySwal;
        const html = <AddCoinsModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: value ? content.edit_coins : content.add_coins,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showAddSystemPayoutAlert(id, value, cb) {
        const popup = MySwal;
        const html = <AddSystemPayoutModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: value ? content.edit_system_payout : content.add_system_payout,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showAddCoinsReferralAlert(id, value, cb) {
        const popup = MySwal;
        const html = <AddCoinsReferralModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: value ? content.edit_coinsReferral : content.add_coinsReferral,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showAddCoins2ndGenReferralAlert(id, value, cb) {
        const popup = MySwal;
        const html = <AddCoins2ndGenModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: value ? content.edit_coins2ndGenReferral : content.add_coins2ndGenReferral,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showAddCoins3rdGenReferralAlert(id, value, cb) {
        const popup = MySwal;
        const html = <AddCoins3rdGenModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: value ? content.edit_coins3rdGenReferral : content.add_coins3rdGenReferral,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showAddPercentageAlert(id, value, cb) {
        const popup = MySwal;
        const html = <AddPercentageModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: value ? content.edit_percentage : content.add_percentage,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showAddPercentageReferralAlert(id, value, cb) {
        const popup = MySwal;
        const html = <AddPercentageReferralModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: value ? content.edit_percentageReferral : content.add_percentageReferral,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showAddPercentage2ndGenReferralAlert(id, value, cb) {
        const popup = MySwal;
        const html = <AddPercentage2ndGenModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: value ? content.edit_percentage2ndGenReferral : content.add_percentage2ndGenReferral,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showAddPercentage3rdGenReferralAlert(id, value, cb) {
        const popup = MySwal;
        const html = <AddPercentage3rdGenModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: value ? content.edit_percentage3rdGenReferral : content.add_percentage3rdGenReferral,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showSetPlatformTypeAlert(value, offerID, cb) {
        const popup = MySwal;
        const html = <SetPlatformTypeModalForm
            offerID={offerID}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: content.edit_offer_platform_type,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showActivateOfferAlert() {
        const popup = MySwal;
        const html = <ActivateOfferModalForm handleCancel={popup.clickCancel} handleConfirm={popup.clickConfirm} />;

        popup.fire({
            html,
            title: content.activate_offer,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showSendHotOfferAlert() {
        const popup = MySwal;
        const html = <ActivateOfferModalForm handleCancel={popup.clickCancel} handleConfirm={popup.clickConfirm} isSendHotOfferForm={true} />;

        popup.fire({
            html,
            title: content.send_hot_offer,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showManageSubscriptionsAlert() {
        const popup = MySwal;
        const HtmlComponent = withSubcriptionData(ManageSubcriptionsModalForm, popup.clickConfirm);
        const html = <HtmlComponent />;

        popup.fire({
            html,
            title: content.manage_subscriptions,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }

    static showValidateAlert(providerFilter) {
        const popup = MySwal;
        const html = <ValidateModalForm providerFilter={providerFilter} />;

        popup.fire({
            html,
            title: content.validate,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'text-left font bold black margin-bottom-20',
                closeButton: 'admin-modal-close-button'
            },
        });
    }


    static showGuidesAlert(id, uniqueOfferID, value, cb) {
        const popup = MySwal;
        const html = <AddGuidesModalForm
            id={id}
            uniqueOfferID={uniqueOfferID}
            values={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: value ? content.edit_guides : content.add_guides,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }


    static showPositionAlert(id, value, cb) {
        const popup = MySwal;
        const html = <EditOfferPositionModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: 'Edit Best Offer Position',
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }


    static showNewOfferAlert(callback) {
        const popup = MySwal;
        const html = <AddNewOfferForm
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={callback}
        />;

        popup.fire({
            html,
            title: 'New Offer',
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: true,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50 margin-bottom-40',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }


    static showAddNewProviderAlert(callback) {
        const popup = MySwal;
        const html = <AddNewProviderModalForm
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={callback}
        />;

        popup.fire({
            html,
            title: "Provider",
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: true,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
            },
        });
    }


    static showUserOfferCompleteAlert(userId, callback) {
        const popup = MySwal;
        const html = <OfferCompleteModalForm
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={callback}
            userId={userId}
        />;

        popup.fire({
            html,
            title: `Manual Offer Complete (user: ${userId})`,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: true,
            width: 825,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
            },
        });
    }


    static showVideoGuidelineUploaderAlert(uniqueOfferID, video, image, updateItems ) {
        const popup = MySwal;
        const html = <VideoGuidelineUploaderForm
            uniqueOfferID={uniqueOfferID}
            video={video}
            image={image}
            confirmCB={updateItems}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
        />;

        popup.fire({
            html,
            title: "Video Guideline",
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: true,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description text-left',
                closeButton: 'admin-modal-close-button'
            },
        });
    }


    static showClickthroughAlert(offerId, value, callback) {
        const popup = MySwal;
        const html = <EditClickthroughModalForm
            offerId={offerId}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={callback}
        />;

        popup.fire({
            html,
            title: `Edit Clickthrough URL`,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: true,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
            },
        });
    }


    static showClickthroughCapAlert(offerId, feildValue, callback) {
        const popup = MySwal;
        const html = <EditClickthroughCapModalForm
            offerId={offerId}
            value={feildValue}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={callback}
        />;

        popup.fire({
            html,
            title: "Edit Clickthrough Limit",
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: true,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
            },
        });
    }

    static async showStockOffersConfigurationAlert(uniqueOfferID, callback) {
        const popup = MySwal;
        const html = <StockOffersModalForm
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={callback}
            uniqueOfferID={uniqueOfferID}
        />;

        await popup.fire({
            html,
            title: `Stock Options`,
            background: '#FFF',
            showCloseButton: true,
            showConfirmButton: false,
            width: 1300,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
            },
        });
    }

    static async show2ndConversionAlert(uniqueOfferID) {
        const popup = MySwal;
        const html = <SecondConversionModalForm
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            //confirmCB={callback}
            uniqueOfferID={uniqueOfferID}
        />;

        await popup.fire({
            html,
            title: `2nd conversion offer`,
            background: '#FFF',
            showCloseButton: true,
            showConfirmButton: false,
            width: 800,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
            },
        });
    }


    static async showOfferReviewTopic(reviewTopicID, callback) {
        const popup = MySwal;
        const html = <OfferReviewTopicModalForm
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={callback}
            reviewTopicID={reviewTopicID}
        />;

        popup.fire({
            html,
            title: "Edit Offer Review Topic",
            background: '#FFF',
            showCloseButton: true,
            showConfirmButton: false,
            allowOutsideClick: true,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
            },
        });
    }


    static validateCoinsAlert(actionData) {
        const popup = MySwal;
        const html = <ValidateCoinsModalForm
            actionData={actionData}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
        />;

        popup.fire({
            html,
            title: 'Confirm validation',
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }
    static changeUserStatusAlert(actionData) {
        const popup = MySwal;
        const html = <DeleteSuspendModalForm
            actionData={actionData}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
        />;

        popup.fire({
            html,
            title: `${actionData?.type} user confirmation`,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }
    static addEditNoteAlert(actionData) {
        const popup = MySwal;
        const html = <AddNoteModalForm
            actionData={actionData}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
        />;

        popup.fire({
            html,
            title: actionData?.heading,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }
    static bonusFineUser(actionData) {
        const popup = MySwal;
        const html = <AddBonusFineModalForm
            actionData={actionData}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
        />;

        popup.fire({
            html,
            title: actionData?.heading,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }
    static simulateChargebackAlert(actionData) {
        const popup = MySwal;
        const html = <SimulateChargebackModalForm
            actionData={actionData}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
        />;

        popup.fire({
            html,
            title: actionData?.heading,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showEditAPIKeyAlert(id, value, cb) {
        const popup = MySwal;
        const html = <EditAPIKeyModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: content.edit_api_key,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static showEditCallbackSecretAlert(id, value, cb) {
        const popup = MySwal;
        const html = <EditCallbackSecretModalForm
            id={id}
            value={value}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
            confirmCB={cb}
        />;

        popup.fire({
            html,
            title: content.edit_callback_secret,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }
    
    static activateOfferSuccessAlert(moreOffersToApprove) {
        const popup = MySwal;
        const html = <ActivateOfferSuccess
            moreOffersToApprove={moreOffersToApprove}
            handleCancel={popup.clickCancel}
        />;

        popup.fire({
            html,
            title: 'Success',
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }

    static handleWithdrawalAlert(actionData) {
        const popup = MySwal;
        const html = <WithdrawalModalForm
            actionData={actionData}
            handleCancel={popup.clickCancel}
            handleConfirm={popup.clickConfirm}
        />;

        popup.fire({
            html,
            title: `Withdrawal ${actionData.variant === withdrawalActionTypes.approve ? withdrawalActionTypes.approve : actionData.variant === withdrawalActionTypes.reject ? withdrawalActionTypes.reject : withdrawalActionTypes.info}`,
            background: '#FFF',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            width: 600,
            customClass: {
                popup: 'admin-swal-popup',
                title: 'close-modal-description padding-left-0 text-left margin-x-50',
                closeButton: 'admin-modal-close-button',
                htmlContainer: 'margin-x-50 overflow-visible'
            },
        });
    }
}

export default Alerts;