import { Box, Button, CircularProgress, Fade, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { apiArchiveCompetitionScores, apiGetInitialCompetitionList, apiGiveCompetitionCoins } from '../../services/CompetitionsService';
import Alerts from '../../utils/Alerts';
import Formatting from '../../utils/Formatting';
import DataTable from '../tables/DataTable';
import { UserContext } from 'contexts/UserContext.js';
import { useNavigate } from 'react-router-dom';

const configuration = require('configuration.json');
const paths = configuration.paths;

function Winners_Tab() {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [competitors, setCompetitors] = useState([]);

  const handleRedirectToUser = ({ userDetails }) => {
    setUser(userDetails);
    navigate(`${paths.user_path}?uniqueUserID=${userDetails.uniqueUserID}`);
  };

  const columns = [
    { label: "Competition ID", id: "CompetitionID", width: 20 },
    { label: "Position", id: "Position", width: 20 },
    {
      label: "Unique ID", id: "UniqueID", width: 100,
      parse: (val, row) => {
        return (
          <span className='text-underline-hover' onClick={(e) => {
            e.stopPropagation();
            handleRedirectToUser({
              userDetails: {
                uniqueUserID: row.UniqueID,
                uniqueID: row.UniqueID,
                ID: row.UserID,
              }
            });
          }}>
            {val}
          </span>
        );
      }
    },
    {
      label: "Email", id: "Email", width: 100,
      parse: (val, row) => {
        return (
          <span className='text-underline-hover' onClick={(e) => {
            e.stopPropagation();
            handleRedirectToUser({ userDetails: { uniqueUserID: row.UniqueID } });
          }}>
            {val}
          </span>
        );
      }
    },
    { label: "Telegram Username", id: "TelegramUsername", width: 20 },
    { label: "Score", id: "Score", width: 20 },
    {
      label: "Last Coins Winning Time", id: "LastCoinsWinningTime", width: 200,
      parse: (val, row) => {
        return <span>{Formatting.formatDate(val, 'yyyy-MM-dd hh:mm')}</span>;
      }
    },
  ];


  const createPotentialWinners = async () => {
    setLoading(true);
    try {
      const res = await apiArchiveCompetitionScores();
      if (res.code === 1) {
        Alerts.showSuccessAlert(`Generated, START: ${Formatting.formatDate(res?.currentCompetitionStartDate)} END: ${Formatting.formatDate(res?.currentCompetitionEndDate)}`);
      } else {
        Alerts.showErrorAlert(res.message || 'Failed');
      }  
    } catch (err) {
      Alerts.showErrorAlert(err.message || 'Failed');
    } finally {
      setLoading(false);
    }

  };
  const fetchPotentialWinners = async () => {
    setLoading(true);
    try {
      const res = await apiGetInitialCompetitionList();
      setCompetitors(res);
    } catch (err) {
      Alerts.showErrorAlert(err.message || 'Failed');
    } finally {
      setLoading(false);
    }

  };


  const giveCompetitionCoins = async () => {
    setLoading(true);
    try {
      const res = await apiGiveCompetitionCoins();
      if (res.code === 1) {
        Alerts.showSuccessAlert(res.message || 'Success');
      } else {
        Alerts.showErrorAlert(res.message || 'Failed');
      }
    } catch (err) {
      Alerts.showErrorAlert(err.message || 'Failed');
    } finally {
      setLoading(false);
    }

  };


  return (<>
    {loading && (
      <Box className="loader">
        <CircularProgress />
      </Box>
    )}
    <Box sx={{ marginTop: 4, marginBottom: 2, textAlign: 'center' }}>
      <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
        ** These actions should be done after the competition ends.
      </Typography>
    </Box>
    <Box sx={{ padding: 1, display: 'flex', flexDirection: 'column', alignItems:'center' }}>
      <Button
        sx={{ marginBottom: '20px',  width: '30%',}}
        fullWidth
        variant="contained"
        color="primary"
        size="medium"
        onClick={createPotentialWinners}
      >
        1. Generate potential winners
      </Button>
      <Button
        sx={{ marginBottom: '20px', width: '30%', }}
        fullWidth
        variant="contained"
        color="secondary"
        size="medium"
      onClick={fetchPotentialWinners}
      >
        2. Receive list
      </Button>
    </Box>
    <Fade in={competitors.length > 0} timeout={700}>
      <Box>
        {competitors.length > 0 ? (
          <>
            <Typography variant="body2" color="textSecondary"
              sx={{ fontStyle: 'italic', marginBottom: 2 }}>
              **👇 You can navigate to User clicking on Unique ID or Email  to see more details and change status
            </Typography>
            <DataTable
              columns={columns}
              tableData={competitors}
              fullHeight
            />
            <Box sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Button
                sx={{ marginBottom: '20px', width: '30%', }}
                fullWidth
                variant="contained"
                color="success"
                size="medium"
                onClick={() => Alerts.showConfirmAlert('Are you sure to send Competition Coins?', giveCompetitionCoins)}
              >
                3. Give Competition Coins
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ textAlign: 'center', padding: 4 }}>
            <Typography variant="h6" color="textSecondary">
              No competitors data available.
            </Typography>
          </Box>
        )}
      </Box>
    </Fade>
  </>
  )
}

export default Winners_Tab