import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import { ShineBorder } from 'components/widgets/Shine_Border.js';
import Formatting from 'utils/Formatting.js';

const CompetitionDetails = ({ data }) => {
    if (!data || Object.keys(data).length === 0) {
        return (
            <Box sx={{ padding: 3, textAlign: 'center' }}>
                <Typography variant="h6" color="textSecondary">
                    No competition data available
                </Typography>
            </Box>
        );
    }

    return (
        <ShineBorder borderWidth={3} colors={["#ffe100", "#fab3ff", "#b298fffb", "#00ff66",]}>
            <Paper elevation={3} sx={{ padding: 2, marginTop: 3, maxWidth: '600px', margin: '0 auto' }}>
            <Typography variant="h5" gutterBottom align="center">
                Current Competition Details
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="textSecondary">
                        Current Competition ID:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{data.CurrentCompetitionID || 'N/A'}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="textSecondary">
                        Start Date:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                        <Typography variant="body1">{Formatting.formatDate(data.StartDate) || 'N/A'}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="textSecondary">
                        End Date:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                        <Typography variant="body1">{Formatting.formatDate(data.EndDate) || 'N/A'}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="textSecondary">
                        Region:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{data.Region || 'N/A'}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="textSecondary">
                        Date Created:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                        <Typography variant="body1">{Formatting.formatDate(data.DateCreated) || 'N/A'}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="textSecondary">
                        Date Modified:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                        <Typography variant="body1">{Formatting.formatDate(data.DateModified) || 'N/A'}</Typography>
                </Grid>
            </Grid>
            </Paper>
        </ShineBorder>
    );
};

export default CompetitionDetails;
