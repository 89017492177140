import { Box, Checkbox, InputLabel } from '@mui/material';
import React, { useState } from 'react';

const content = require('content.json');
const configuration = require('configuration.json');
const withdrawalActionTypes = configuration.withdrawals_action_types;

function WithdrawalModalForm({
    actionData,
    handleCancel,
    handleConfirm,
}) {

    const {
        UserID,
        UserName,
        Email,
        platform,
        Coins,
        Amount,
        pixType,
        pixKey,
        additionalProps,
        variant,
    } = actionData;
    const [sendNotificationToUser, setSendNotificationToUser] = useState(variant === 'approve' ? true : false);

    return (
        <div className='margin-top-20 '>
            <Box>
                <div className="withdrawals-modal-line">
                    <p>User ID: <span>#{UserID}</span></p>
                    <p>Username: <span>{UserName}</span></p>
                </div>
                <div className="withdrawals-modal-line">
                    <p>Email Address: <span>{Email}</span></p>
                    <p>Platform: <span>{platform}</span></p>
                </div>
                <div className="withdrawals-modal-line">
                    <p>Treat Coins Amount to Withdraw: <span>{Coins}</span></p>
                </div>
                <div className="withdrawals-modal-line">
                    <p>Amount to Withdraw: <span>{Amount}</span></p>
                </div>
                {additionalProps && (
                    <>
                        <div className="withdrawals-modal-line">
                            <p>Pix type: <span>{pixType}</span></p>
                        </div>
                        <div className="withdrawals-modal-line">
                            <p>Pix key: <span>{pixKey}</span></p>
                        </div>
                        <div className="withdrawals-modal-line">
                            <p>CPF / CNPJ: <span>{additionalProps.pixKey.CPFCNPJ}</span></p>
                        </div>
                    </>
                )}
            </Box>
            {variant === 'approve' ?
                <div className='flex-actionData items-center flex justify-center'>
                    <Checkbox
                        size="small"
                        className="grid-list_basic-cell-checkbox"
                        checked={sendNotificationToUser}
                        onChange={(e) => {
                            setSendNotificationToUser(e.target.checked);
                        }}
                    />
                    <InputLabel htmlFor="reason-field">Send notification to user</InputLabel>
                </div> :
                <></>}
            {(variant === withdrawalActionTypes.approve || variant === withdrawalActionTypes.reject) ? (
                <>
                    <button
                        onClick={() => {
                            actionData.handleConfirm(sendNotificationToUser);
                            handleConfirm();
                        }}
                        style={{
                            backgroundColor: variant === withdrawalActionTypes.reject ? '#d31c1c' : '#2e7d32'
                        }}
                        className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'
                    >
                        {variant === withdrawalActionTypes.reject ? 'Reject' : 'Approve'}
                    </button>
                    <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
                </>
            ) : null}
        </div>
    );
}

export default WithdrawalModalForm;