import Backend from 'Backend';
import Alerts from 'utils/Alerts.js';

export const apiGetWithdrawals = async (filter) => {

    const queryString = new URLSearchParams(filter).toString();

    const res = await fetch(`${Backend.backendURLs.listWithdrawalsUrl}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    } else if (res.status === Backend.backendResponseCodes.OKCode) {
        return await res.json();
    } else {
        Alerts.showErrorAlert('Something went wrong');
    }

}

export const apiRejectWithdrawal = async ({ id }) => {
    try {
        const res = await fetch(Backend.backendURLs.rejectWithdrawUrl, {
            method: Backend.backendMethods.patch,
            headers: Backend.generateHeader(),
            body: JSON.stringify({
                withdrawalID: id,
                action: 2,
                sendNotificationToUser: false,
            })
        });
        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        }
        return await res.json();
    } catch (error) {
        return error;
    }
    
}


export const apiApproveWithdrawal = async ({ id, sendNotificationToUser }) => {
    try {
        const res = await fetch(Backend.backendURLs.approveWithdrawUrl, {
            method: Backend.backendMethods.patch,
            headers: Backend.generateHeader(),
            body: JSON.stringify({
                withdrawalID: id,
                action: 3,
                sendNotificationToUser,
            })
        });
        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        }
        return await res.json();
    } catch (error) {
        return error;
    }

}

export const apiGetWithdrawProps = async (props) => {

    const queryString = new URLSearchParams(props).toString();

    const res = await fetch(`${Backend.backendURLs.getWithdrawPropsUrl}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}

export const apiGetWithdrawalCoinsEarnings = async (filter) => {

    try {
        const queryString = new URLSearchParams(filter).toString();

        const res = await fetch(`${Backend.backendURLs.getWithdrawPropsUrl}?${queryString}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader()
        });

        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.OKCode) {
            return await res.json();
        } else {
            Alerts.showErrorAlert('Something went wrong');
        }
    } catch (error) {
        Alerts.showErrorAlert('Something went wrong');
    }
}

