import Header from 'components/text/Header.js';
import BannedPostbacksTable from 'components/tables/Banned_Postbacks_Table.js';


function BannedPostbacks() {

    return (
        <div className="banned-postbacks-page">
            <Header />
            <BannedPostbacksTable/>
        </div>
    );
}

export default BannedPostbacks;