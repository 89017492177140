import DropDown from 'components/inputs/DropDown.js';
import { useEffect, useState } from 'react';
import 'design/withdrawals.css';
import Header from 'components/text/Header';
import { Box, } from '@mui/material';
import DataTable from '../components/tables/DataTable';
import { apiGetWithdrawals, apiRejectWithdrawal, apiApproveWithdrawal, apiGetWithdrawProps, apiGetWithdrawalCoinsEarnings } from '../services/WithdrawalsService';
import { Toolbar, Paper } from '@mui/material';
import Images from 'utils/Images';
import DataFilter from '../components/filters/DataFilter';
import SearchBySelect from '../components/inputs/SearchBySelect';
import SearchField from '../components/inputs/SearchField';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Modal from '../utils/Modal';
import Alerts from 'utils/Alerts.js';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';


const content = require('content.json');
const withdrawalsActions = content.withdrawals_actions;
const configuration = require('configuration.json');
const withdrawalActionTypes = configuration.withdrawals_action_types;
const paths = configuration.paths;



const Withdrawals = () => {

    const initialFilter = JSON.parse(sessionStorage.getItem("withdrawalsFilter")) || {
        order: "desc",
        orderBy: "ID",
        page: 1,
        pageSize: 20,
        search: "",
        searchBy: "",
        dueDateRangeSelection: 0
    };

    const dueDateFilterOptions = [
        { label: '-', value: 0 },
        { label: 'Past due and due today', value: 1 },
        { label: 'Due tomorrow', value: 2  },
        { label: 'Due in 2 days', value: 3 },
        { label: 'Due in 3 days', value: 4 },
        { label: 'All', value: 0}
    ];

    const [withdrawals, setWithdrawals] = useState([])
    const [filter, setFilter] = useState(initialFilter);
    const [totalRows, setTotalRows] = useState(0);
    const [loader, setLoader] = useState(false);
    const [pageSizeInput, setPageSizeInput] = useState(filter.pageSize);
    const navigate = useNavigate();

    const handlePageSizeChange = (e) => {
        const value = e.target.value;
        if (value === "" || (Number(value) > 0 && Number.isInteger(Number(value)))) {
            setPageSizeInput(value);
        }
    };

    const applyPageSize = () => {
        const newFilter = { ...filter, pageSize: Number(pageSizeInput) || 20, page: 1 };
        setFilter(newFilter);
        sessionStorage.setItem("withdrawalsFilter", JSON.stringify(newFilter)); 
    };

    const handleRedirectToUser = (UniqueUserID) => {
        sessionStorage.setItem("withdrawalsFilter", JSON.stringify(filter));
        navigate(`${paths.user_path}?uniqueUserID=${UniqueUserID}`);
    };


    const columns = [
        {
            label: 'ID',
            id: 'ID',
        },
        {
            label: 'Coins earnings',
            id: 'action_withdrawal_details',
            sortable: false,
            parse: (val, row) => {
               return <Stack direction="row" spacing={1}>
                        <Chip 
                            label="View list"
                            icon={<InfoIcon />}  
                            variant="outlined" 
                            color="info" 
                            onClick={async() => await openActionPopup(row, 'list')}
                            size="small"
                        />
                </Stack>
            },
            filterable: false
        },
        {
            label: 'Date Created',
            id: 'DateCreated',
            filterable: false
        },
        {
            label: 'Payment due date',
            id: 'DueDate',
        },
        {
            label: 'Username',
            id: 'UserName',
            parse: (val, row) => {
                return (
                    <span className='text-underline-hover' onClick={(e) => {
                        e.stopPropagation();
                        handleRedirectToUser(row.UniqueUserID);
                    }}>
                        {val}
                    </span>
                );
            },
        },
        {
            label: 'Unique user ID',
            id: 'UniqueUserID',
            parse: (val, row) => {
                return (
                    <span className='text-underline-hover' onClick={(e) => {
                        e.stopPropagation();
                        handleRedirectToUser(row.UniqueUserID);
                    }}>
                        {val}
                    </span>
                );
            },
        },
        {
            label: 'Email',
            id: 'Email',
        },
        {
            label: 'Platform',
            id: 'platform',
        },
        {
            label: 'Amount',
            id: 'Coins',
            filterable: false
        },
        {
            label: 'Amount to Withdraw',
            id: 'Amount',
            filterable: false
        },
        {
            label: 'Status',
            id: 'status',
            parse: (val, row) => {
                return (<div className={`grid-list_basic-cell_${val} badge`}>{val}</div>)
            }
        },
        {
            label: 'Date Modified',
            id: 'DateModified',
            filterable: false
        },
        {
            label: 'Action',
            id: 'action_withdrawal',
            sortable: false,
            parse: (val, row) => {
                return row.status === "pending" ? (
                    <Stack direction="row" spacing={1}>
                        <Chip 
                            label="Approve"
                            icon={<CheckCircleIcon />}  
                            variant="outlined" 
                            color="success" 
                            onClick={async() => await openActionPopup(row, 'approve')}
                            size="small"
                        />
                        <Chip 
                            label="Reject"
                            icon={<CancelIcon />}  
                            variant="outlined" 
                            color="error" 
                            onClick={async() => await openActionPopup(row, 'reject')}
                            size="small"
                        />
                    </Stack>
                ):
                (row.status === "approved") ? (
                    <Stack direction="row" spacing={1}>
                    <Chip 
                        label="See details"
                        icon={<InfoIcon />}  
                        variant="outlined" 
                        color="info" 
                        onClick={async() => await openActionPopup(row, 'info')}
                        size="small"
                    />
                </Stack>
                ): (null)
            },
            filterable: false
        },
    ];


    const coinsEarningsColumns = [
        {
            id: 'offerCompletionDate',
            label: 'Offer Completion Date',
        },
        {
            id: 'providerName',
            label: 'Provider',
        },
        {
            id: 'uniqueOfferID',
            label: 'Unique Offer ID',
        },
        {
            id: 'appName',
            label: 'Offer App Name',
        },
        {
            id: 'actionName',
            label: 'Offer Description',
        },
        {
            id: 'treatCoinsEarned',
            label: 'TCs Earned',
        },
        {
            id: 'treatCoinsUsed',
            label: 'TCs Used',
        },
        {
            id: 'postbackRequestID',
            label: 'Postback Request ID',
        },
        {
            id: 'manualByAdminName',
            label: 'Postback Forced by Admin',
        },
        {
            id: 'validatedByAdminName',
            label: 'Validated by Admin',
        },
        {
            id: 'coinsEarningID',
            label: 'Coins Earnings ID',
        }
    ];

    useEffect(() => {
        sessionStorage.removeItem("withdrawalsFilter");
        getWithdrawals();
        sessionStorage.setItem("withdrawalsFilter", JSON.stringify(filter));
    }, [filter]);

    const getWithdrawals = async () => {
        setLoader(true);
        const result = await apiGetWithdrawals(filter);
        setWithdrawals([...result?.withdrawals]);
        setTotalRows(result.total);
        setLoader(false);
    };

    const rejectWithdrawal = async (id) => {
        try {
            const res = await apiRejectWithdrawal({ id });
            if (res.status === '1') {
                Alerts.showSuccessAlert(`Withdrawal with id ${id} been rejected`);
            } else {
                Alerts.showSuccessAlert(res.message + ' rejected' || 'Something went wrong');
            }
        } catch (error) {
            Alerts.showErrorAlert(error.message || 'Something went wrong');
        } finally {
            getWithdrawals();
        }


    };

    const approveWithdrawal = async (id, sendNotificationToUser) => {
        try {
            const res = await apiApproveWithdrawal({ id, sendNotificationToUser });
            if (res.status === 1) {
                Alerts.showSuccessAlert(`Withdrawal with id ${id} been approved`);
            } else {
                Alerts.showSuccessAlert(res.message + ' approved' || 'Something went wrong');
            }
        } catch (error) {
            Alerts.showErrorAlert(error.message || 'Something went wrong');
        } finally {
            getWithdrawals();
        }
    };

    const onFilterChange = filter => {
        setFilter(prev => ({ ...prev, ...filter }));
    }

    const openActionPopup = async (row, variant) => {

        if (variant === withdrawalActionTypes.approve || variant === withdrawalActionTypes.reject || variant === withdrawalActionTypes.info) {
            let additionalProps;
            let pixType;
            let pixKey = "";
            if (row.additionalData) {
                additionalProps = await apiGetWithdrawProps({withdrawalID: row.ID});
                pixType = additionalProps.pixType;
                if (additionalProps.pixKey.email) {
                    pixKey = additionalProps.pixKey.email;
                }
                else if (additionalProps.pixKey.phoneNumber) {
                    pixKey = additionalProps.pixKey.phoneNumber;
                }
                else if (additionalProps.pixKey.randomKey) {
                    pixKey = additionalProps.pixKey.randomKey;
                }
                else {
                    pixKey = additionalProps.pixKey.CPFCNPJ;
                }
            }
            Alerts.handleWithdrawalAlert({
                UserID: row.UserID,
                UserName: row.UserName,
                Email: row.Email,
                platform: row.platform,
                Coins: row.Coins,
                Amount: row.Amount,
                additionalProps,
                pixType,
                pixKey,
                variant,
                handleConfirm: (sendNotificationToUser) => {
                    return variant === withdrawalActionTypes.reject ? rejectWithdrawal(row.ID) : approveWithdrawal(row.ID, sendNotificationToUser);
                },
            })
        }
        else if (variant === 'list') {
           
            let result = await apiGetWithdrawalCoinsEarnings({withdrawalID: row.ID, coinsEarningsList: true});
            let coinsEarnings = result.coinsEarnings;
            Modal.open(
                <Box>
                    <DataTable
                        columns={coinsEarningsColumns}
                        tableData={coinsEarnings}
                        loading={loader}
                        columnWidth={450}
                    />
                </Box>,
                {
                    showConfirmButton: false,
                    title: <span className={`modal-popup-heading modal-heading-${variant}`}>{withdrawalsActions[variant]}</span>,
                    width: 1600
                }
            );
        }
    }

   
    const onDropDownChange = e => {
        setFilter(prev => ({ ...prev, dueDateRangeSelection: e.target.value }));
    }


    return (
        <div className='upload-content'>
            <Header />
            <div className="content-container">
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                justifyContent: "start",
                                columnGap: 1
                            }}
                        >
                            <Box className='flex gap-10'>
                                <img src={Images.imageURL('rectangle.svg')} alt="" />
                                <h3>Withdrawals</h3>
                            </Box>

                            <DataFilter onChange={onFilterChange}>
                                <SearchBySelect data={columns.filter(column => (column.filterable !== false))} />
                                <SearchField />
                                <DropDown
                            onChange={onDropDownChange}
                            value={filter.dueDateFilter}
                            name='dueDate'
                            label='Due date'
                            data={dueDateFilterOptions}
                            />
                            </DataFilter>
                            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '10px 20px' }}>
                                <label htmlFor="page-size-text">Custom Page Size:</label>
                                <input
                                    type="text"
                                    id="page-size-text"
                                    value={pageSizeInput}
                                    onChange={handlePageSizeChange}
                                    style={{ padding: '5px', width: '80px', borderRadius: '5px', border: '1px solid #ccc' }}
                                />
                                <button
                                    onClick={applyPageSize}
                                    style={{
                                        padding: '5px 10px',
                                        backgroundColor: '#007BFF',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Apply
                                </button>
                            </Box>
                        </Toolbar>

                        <DataTable
                            columns={columns}
                            tableData={withdrawals}
                            onFilterChange={onFilterChange}
                            filter={filter}
                            loading={loader}
                            total={totalRows}
                            fullHeight
                        />
                    </Paper>
                </Box>
            </div>
        </div>
    )
}

export default Withdrawals;